<template>
	<div>
		<div class="page-title">
			<a>
				<router-link to="/action/index">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">行动编辑</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" class="w-50">
				<el-form-item label="单位">
					<el-select @change="handleFirstChange" v-model="form.first_id" placeholder="请选择支队">
						<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleSecondChange" v-model="form.second_id" placeholder="请选择大队">
						<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleThirdChange" v-model="form.third_id" placeholder="请请选择街道">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="行动标题" prop="action_title">
					<el-input v-model="form.action_title" placeholder="名称"></el-input>
				</el-form-item>
				<el-form-item label="封面图" prop="image">
					<app-attachment :multiple="false" :max="1" v-model="form.image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.image" :show-delete="true" @deleted="form.image = ''" width="80px"
						height="80px"></app-gallery>
					<div class="pic-url-remark">封面图片尺寸推荐750*350</div>
				</el-form-item>
				<el-form-item label="是否显示" prop="action_status">
					<el-radio v-model="form.action_status" :value="10" :label="10">
						显示
					</el-radio>
					<el-radio v-model="form.action_status" :value="20" :label="20">
						隐藏
					</el-radio>
				</el-form-item>
				<el-form-item label="排序" prop="action_sort">
					<el-input-number :min="0" v-model="form.action_sort" placeholder="排序"></el-input-number>
				</el-form-item>
				<el-form-item label="行动内容">
					<app-rich-text :key="timer" v-model="form.action_content"></app-rich-text>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText
		},
		data() {
			return {
				loading: false,
				activeName: 'basic',
				timer: new Date().getTime(),
				firstList: [],
				secondList: [],
				thirdList: [],
				form: {
					action_title: '',
					image: '',
					action_content: '',
					link_url: '',
					action_status: 10,
					action_sort: 50,
					first_id: '',
					second_id: '',
					third_id: 0,
					first_unit_name: '',
					second_unit_name: '',
					third_unit_name: '',
				},
				rules: {
					action_title: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					action_sort: [{
						required: true,
						message: '请输入排序',
						trigger: 'change'
					}],
					image: [{
						required: true,
						message: '请选择图片',
						trigger: 'change'
					}, ],
				},
			}
		},
		created() {
			this.getUnitList()
		},
		methods: {
			handleFirstChange(first_id) {
				this.form.second_id = '';
				this.form.third_id = 0;
				this.form.second_unit_name = '';
				this.form.third_unit_name = '';
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
						this.form.first_unit_name = this.firstList[i].unit_name;
					}
				}
			},
			handleSecondChange(second_id) {
				this.form.third_id = 0;
				this.form.third_unit_name = '';
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
						this.form.second_unit_name = this.secondList[i].unit_name;
					}
				}
			},
			handleThirdChange(third_id) {
				for (let i = 0; i < this.thirdList.length; i++) {
					if (this.thirdList[i]['unit_id'] == third_id) {
						this.form.third_unit_name = this.thirdList[i].unit_name;
					}
				}
			},
			getDetail() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/action/edit',
						action_id: this.$route.params.action_id
					},
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						this.form.action_title = res.data.data.action_title;
						this.form.image = res.data.data.image;
						this.form.action_content = res.data.data.action_content;
						this.form.action_status = res.data.data.action_status;
						this.form.action_sort = res.data.data.action_sort;
						this.form.first_id = res.data.data.first_id;
						this.form.second_id = res.data.data.second_id;
						this.form.third_id = res.data.data.third_id;
						this.form.first_unit_name = res.data.data.first_unit_name;
						this.form.second_unit_name = res.data.data.second_unit_name;
						this.form.third_unit_name = res.data.data.third_unit_name;
						if (this.form.first_id > 0) {
							for (let i = 0; i < this.firstList.length; i++) {
								if (this.firstList[i]['unit_id'] == this.form.first_id) {
									this.secondList = this.firstList[i].child;
								}
							}
						}
						if (this.form.second_id > 0) {
							for (let i = 0; i < this.secondList.length; i++) {
								if (this.secondList[i]['unit_id'] == this.form.second_id) {
									this.thirdList = this.secondList[i].child;
								}
							}
						}
					}
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getUnitList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.firstList = res.data.data
					this.getDetail()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/action/edit',
								action_id: this.$route.params.action_id
							},
							data: {
								action: this.form
							},
							method: 'post',
						}).then(res => {
							this.loading = false;
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'action/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
							this.loading = false;
						})
					}
				})
			}
		}
	}
</script>
<style>
</style>
